<script setup>
import Button from '@/Components/Button/Button.vue';
import DataCard from '@/Components/DataCard.vue';
import SingleImage from '@/Components/Popup/SingleImage.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';
import { objectMap } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    flexer: Object,
});

const tableSettings = [
    { label: t('Name') },
    { label: t('Phone number') },
    { label: t('Email address') },
    { label: t('Address') },
    { label: t('Company name') },
    { label: t('KvK number') },
    { label: t('Legal form') },
];
const tableData = [
    props.flexer.first_name + ' ' + props.flexer.last_name,
    props.flexer.phone_number,
    props.flexer.email,
    props.flexer.address_full,
    props.flexer.company.commercial_name,
    props.flexer.company.kvk_number,
    props.flexer.company.legal_form,
];

const showIdentification = ref(false);

const form = useForm({
    action: '',
    flexer_id: props.flexer.id,
    reason: '',
    verified_identification: false,
    verified_authorized_to_sign: false,
});

const sendForm = () => {
    form.post(route('staff.verified-flexers.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            console.table(errors);
            form.errors = objectMap(errors, (error) => [error]);
        },
    });
};
</script>

<template>
    <StaffLayout :title="$t('Fl@xr')">
        <template #sidebar>
            <div class="w-full h-0 overflow-hidden rounded aspect-w-1 aspect-h-1">
                <img @click="showIdentification = true" :src="flexer.identification_photo" alt="" />
                <SingleImage v-model:open="showIdentification" :image="flexer.identification_photo" />
            </div>
        </template>
        <template #default>
            <DataCard>
                <table class="w-full">
                    <template v-for="(header, i) in tableHeader" :key="i">
                        <tr>
                            <td class="w-3/12 px-4 py-3">
                                <strong>{{ header.label }}</strong>
                            </td>
                            <td>{{ tableData[i] }}</td>
                        </tr>
                    </template>
                </table>
            </DataCard>
            <FormKit type="form" id="registration-example" @submit="sendForm" :actions="false">
                <FormKit
                    type="checkbox"
                    name="verified_identification"
                    :label="$t('Photo identification matches the data.')"
                    v-model="form.verified_identification"
                    :errors="form.errors.verified_identification"
                    :value="form.verified_identification"
                />
                <FormKit
                    type="checkbox"
                    name="verified_authorized_to_sign"
                    :label="$t('Person is authorized to sign.')"
                    v-model="form.verified_authorized_to_sign"
                    :errors="form.errors.verified_authorized_to_sign"
                    :value="form.verified_authorized_to_sign"
                />
                <FormKit
                    type="textarea"
                    name="reason"
                    v-if="!form.verified_authorized_to_sign && !form.verified_identification"
                    :label="$t('Reason for decline')"
                    v-model="form.reason"
                    :errors="form.errors.reason"
                    :value="form.reason"
                />
                <div class="grid gap-4 md:grid-cols-2">
                    <Button
                        @click="form.action = 'accept'"
                        :disabled="!form.verified_authorized_to_sign || !form.verified_identification"
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                    >
                        <div class="flex items-center gap-2">
                            <img class="w-4" src="@/../img/done-white.svg" alt="" />
                            <div class="w-full">
                                {{ $t('Accept') }}
                            </div>
                        </div>
                    </Button>
                    <Button
                        @click="form.action = 'decline'"
                        danger
                        :processing="form.processing"
                        :recentlySuccessful="form.recentlySuccessful"
                    >
                        <div class="flex items-center gap-2">
                            <img class="w-4" src="@/../img/close-white.svg" alt="" />
                            <div class="w-full">
                                {{ $t('Decline') }}
                            </div>
                        </div>
                    </Button>
                </div>
            </FormKit>
        </template>
    </StaffLayout>
</template>
